import { useContext, useState } from "react";
import AuthenticationContextProvider from "./contexts/authentication-context-provider";
import LoadingState from "./components/configuration/loading-states";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ConfigProvider, Spin, theme } from "antd";
import InternalServer from "./errors/internal-server";
import { createUseStyles } from "react-jss";
import SuspendedAccount from "./errors/suspended-account";
import AppRouter from "./routes";
import CompanyContextProvider from "./contexts/company-context-provider";
import CorporationSelect from "./components/corporation-select";
import "antd/dist/reset.css";
import { ErrorBoundary } from "react-error-boundary";
import enUS from "antd/locale/en_US";
import { DarkModeContext } from "./contexts/theme-context";
import NewCorporationLayout from "./components/corporation/corp-new-layout";

const useStyles = createUseStyles({
  loadingSpinner: {
    left: "50%",
    top: "50%",
    position: "fixed",
  },
});

const App = (): JSX.Element => {
  const [loading, setLoading] = useState(LoadingState.Loading);
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [companySelect, setCompanySelect] = useState<boolean>(
    localStorage.getItem("companySelect") === "true"
  );

  const classes = useStyles();

  const { darkMode } = useContext(DarkModeContext);

  if (inProgress === "login")
    return <Spin className={classes.loadingSpinner} tip="Authenticating..." />;

  if (
    (!isAuthenticated && loading !== LoadingState.Loading) ||
    loading === LoadingState.Unauthorized
  ) {
    //<Unauthorized />
    // Returning empty so that we can redirect to Authentication page.
    return <></>;
  }
  if (loading === LoadingState.Error)
    return <InternalServer displayHome={false} />;

  /*if (loading === LoadingState.NoActiveCorp)
    return <InternalServer displayHome={false} />;*/

  if (loading === LoadingState.Inactive) return <SuspendedAccount />;

  const handleCompanyUpdate = (requireCompanySelect: boolean) => {
    setCompanySelect(requireCompanySelect);
  };

  const pathname = window.location.pathname;

  //Other error routes will go here that we don't want to have a custom layout.
  return (
    <AuthenticationContextProvider setLoading={setLoading} loading={loading}>
      {loading === LoadingState.Loading ? (
        <Spin
          className={classes.loadingSpinner}
          tip="Loading user information..."
        />
      ) : (
        <ErrorBoundary
          FallbackComponent={() => InternalServer({ displayHome: true })}
        >
          <CompanyContextProvider setLoading={setLoading}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF5532",
                },
                algorithm: darkMode
                  ? theme.darkAlgorithm
                  : theme.defaultAlgorithm,
                //algorithm: theme.darkAlgorithm,
              }}
              locale={enUS}
            >
              {pathname.includes("join") ? (
                <NewCorporationLayout />
              ) : companySelect ? (
                <CorporationSelect handleCompanyUpdate={handleCompanyUpdate} />
              ) : (
                <AppRouter />
              )}
            </ConfigProvider>
          </CompanyContextProvider>
        </ErrorBoundary>
      )}
    </AuthenticationContextProvider>
  );
};
export default App;
