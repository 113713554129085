import { LikeOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Typography,
  Statistic,
  Table,
  Badge,
  Avatar,
  Space,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { CompanyContext } from "../../contexts/company-context";
import { AmPMFormat, ConvertTo12hr } from "../../Util/dateutil";
import {
  Employee,
  EmployeeActivity,
  ScheduleReportQuery,
} from "../../serviceoperation/entity/ScheduleOperationEntity";
import { StatisticCard } from "@ant-design/pro-card";
import RcResizeObserver from "rc-resize-observer";
import Notification from "./notification";

type ScheduleHistory = {
  employee: Employee;
  scheduleId: React.Key;
  title: string;
  note?: string;
  scheduleDate: string;
  scheduleTime: string;
  startTime: string;
  endTime: string;
  employeeActivity?: EmployeeActivity;
};

const imgStyle = {
  display: "block",
  width: 42,
  height: 42,
};

const Manager = () => {
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { Title, Paragraph } = Typography;
  const [responsive, setResponsive] = useState(false);
  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const { selectedCompany } = useContext(CompanyContext);
  const [history, setHistory] = useState<ScheduleHistory[]>([]);
  const scheduleOps = new ScheduleOperation();

  const [onSchedule, setOnSchedule] = useState<number>(0);
  const [online, setOnline] = useState<number>(0);
  const [offline, setOffline] = useState<number>(0);
  const [clockedOut, setClockedOut] = useState<number>(0);

  const fetchScheduleReport = async (options: ScheduleReportQuery) => {
    return await scheduleOps.getScheduleReportAsync(
      selectedCompany?.corporationId as number,
      options
    );
  };

  const getFormattedDateTime = (dateTimeString?: string) => {
    if (dateTimeString) {
      return `${new Date(dateTimeString + "Z").toLocaleString()}`;
    }
    return "";
  };

  const fetchScheduleReportData = async (options: ScheduleReportQuery) => {
    return await fetchScheduleReport(options).then((response) =>
      response?.data?.results?.map((result) => ({
        employee: result.employee,
        scheduleId: result.scheduleId,
        title: result.title,
        note: result.note,
        scheduleDate: result.scheduleDate,
        scheduleTime: `${ConvertTo12hr(result.startWorkHour)}:${
          result.startWorkMinute === 0 ? "00" : result.startWorkMinute
        } ${AmPMFormat(result.startWorkHour)} - ${ConvertTo12hr(
          result.endWorkHour
        )}:${
          result.endWorkMinute === 0 ? "00" : result.endWorkMinute
        } ${AmPMFormat(result.endWorkHour)}`,
        startTime: getFormattedDateTime(result.employeeActivity?.clockedInAt),
        endTime: getFormattedDateTime(result.employeeActivity?.clockedOutAt),
        employeeActivity: result.employeeActivity,
      }))
    );
  };

  useEffect(() => {
    const options: ScheduleReportQuery = {
      //weekOf: new Date().toDateString(),
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      page: 1,
      pageSize: 25,
      IncludeDirReport: true,
    };
    fetchScheduleReportData(options).then(setHistory);
  }, [selectedCompany, userInfo]);

  //Data Stats...
  useEffect(() => {
    if (history) {
      let onScheduleCount = 0;
      let onlineCount = 0;
      let offlineCount = 0;
      let clockedOutCount = 0;
      const employeeIDs = new Set();

      history.forEach((x) => {
        if (x.employeeActivity) {
          if (
            x.employeeActivity.clockedInAt &&
            !x.employeeActivity.clockedOutAt
          ) {
            onlineCount++;
          } else if (!x.employeeActivity.clockedInAt) {
            offlineCount++;
          } else if (
            x.employeeActivity.clockedInAt &&
            x.employeeActivity.clockedOutAt
          ) {
            clockedOutCount++;
          }
        }

        employeeIDs.add(x.employee.employeeID);
      });

      onScheduleCount = employeeIDs.size;

      setOnSchedule(onScheduleCount);
      setOnline(onlineCount);
      setOffline(offlineCount);
      setClockedOut(clockedOutCount);
    }
  }, [history]);

  const columns: ColumnsType<ScheduleHistory> = [
    {
      title: "Employee",
      width: "20%",
      render: (record) => (
        <>
          {record.employee.firstName} {record.employee.lastName}
          <br />
          {record.scheduleTime}
        </>
      ),
      responsive: ["xs"],
    },
    {
      title: "Employee",
      render(text, record) {
        return (
          <Space>
            <Avatar
              src={
                <img
                  src="https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg"
                  alt="avatar"
                />
              }
            />
            <div>
              {record.employee.firstName} {record.employee.lastName}
              <br />
              {record.employee.title}
            </div>
          </Space>
        );
      },
      filters: history.map((s) => ({
        text: s.employee.firstName,
        value: s.employee.firstName,
      })),
      //onFilter: (value: any, record: { name: string }) =>
      //  record.name.startsWith(value),
      filterSearch: true,
      width: "30%",
      responsive: ["sm"],
    },
    {
      title: "Scheduled",
      dataIndex: "scheduleTime",
      width: "30%",
      responsive: ["sm"],
      //sorter: (a: { age: number }, b: { age: number }) => a.age - b.age,
    },
    {
      title: "Status",
      dataIndex: "status",
      //render: (stat) => <Badge status="success" text={stat} />,
      render: (_, record) =>
        record?.startTime ? (
          <Space>
            {record.employeeActivity?.clockedOutAt ? (
              <Badge status="error" />
            ) : (
              <Badge status="success" />
            )}
            <div>
              Clocked In: {record?.startTime}
              <br />
              Clocked Out: {record?.endTime}
            </div>
          </Space>
        ) : null,
      width: "40%",
    },
  ];

  return (
    <RcResizeObserver
      key="resize-observer"
      onResize={(offset) => {
        setResponsive(offset.width < 596);
      }}
    >
      <div style={{ margin: "20px" }}>
        <Row gutter={16}>
          <Col span={18}>
            <Title level={2}>
              <>
                Welcome back, {userInfo?.firstName} {userInfo?.lastName}!
              </>
            </Title>
            <Paragraph>
              Here's what's happening across {selectedCompany?.name} today.
            </Paragraph>
          </Col>
          {/*<Col span={2}>
            <Statistic
              title={
                <>
                  <LikeOutlined />
                  <span>Sales</span>
                </>
              }
              value={"$513.23"}
            />
          </Col>
          <Col span={2}>
            <Statistic
              title={
                <>
                  <LikeOutlined />
                  <span>Est. Labor</span>
                </>
              }
              value={"$161.88"}
            />
          </Col>
          <Col span={2}>
            <Statistic
              title={
                <>
                  <LikeOutlined />
                  <span>Est. Labor %</span>
                </>
              }
              value={"31.5%"}
            />
            </Col>*/}
        </Row>
        <StatisticCard.Group
          direction={responsive ? "column" : "row"}
          style={{ marginTop: 24 }}
        >
          <StatisticCard
            statistic={{
              title: "On Schedule",
              value: onSchedule,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/color/96/male-female-user-group.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Online",
              value: online,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/dusk/96/online.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Offline",
              value: offline,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/dusk/96/offline.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Clocked Out",
              value: clockedOut,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/nolan/96/clock.png"
                  alt="icon"
                />
              ),
            }}
          />
        </StatisticCard.Group>
        <Row gutter={16} style={{ marginTop: "32px" }}>
          <Col xs={24} sm={18} md={18} lg={14} xl={12}>
            <Table
              style={{ whiteSpace: "pre" }}
              title={() => "Today's Schedule"}
              columns={columns}
              dataSource={history}
            />
          </Col>
          <Col xs={10} sm={8} md={8} lg={4} xl={2}></Col>
          {/*<Col xs={24} sm={18} md={18} lg={12} xl={10}>
            <Notification manager={false} empl={false} />
          </Col>*/}
        </Row>
      </div>
    </RcResizeObserver>
  );
};

export default Manager;
