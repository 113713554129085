import ProCard, { StatisticCard } from "@ant-design/pro-card";
import {
  Button,
  Col,
  message,
  Row,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import TimeOffCurrentUser from "./timeoff/time-off-current";
import TimeOffManager from "./timeoff/time-off-manager";
import RcResizeObserver from "rc-resize-observer";
import { PageContainer } from "@ant-design/pro-layout";
import {
  AuditOutlined,
  CloseOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TimeOffRequestModal from "./modal/timeoff-request";
import { RequestTimeOffParam, TimeOffRequestsType } from "./time-off-types";
import { TimeOffOperation } from "../../serviceoperation/TimeoffOperation";
import { ColumnsType } from "antd/es/table";
import { timeOffStatus } from "../../constants";
import { formatDate } from "../../Util/dateutil";
import { useNavigate } from "react-router-dom";
import { TimeOffStatistics } from "../../serviceoperation/entity/TimeOffOperationEntity";

const TimeOff = (): JSX.Element => {
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { Title, Paragraph, Text } = Typography;

  const accessToken = useContext(AuthenticationContext)?.accessToken;
  const [myTimeOff, setMyTimeOff] = useState<TimeOffRequestsType[]>([]);
  const [openTieOffModal, setOpenTieOffModal] = useState(false);
  const [updateTimeOff, SetUpdateTimeOff] = useState(false);
  const [loading, setLoading] = useState(true);
  const timeOffOps = new TimeOffOperation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [isManager, setIsManager] = useState(false);
  const navigate = useNavigate();
  const [stats, setStats] = useState<TimeOffStatistics>();

  const handleEmpTimeOffClick = () => {
    navigate(`/schedule/timeoff/manager`);
  };
  const fetchTimeOff = async (options: RequestTimeOffParam) => {
    return await timeOffOps.getScheduleTimeOffAsync(
      selectedCompany?.corporationId as number,
      options
    );
  };

  const fetchTimeOffData = async (options: RequestTimeOffParam) => {
    return await fetchTimeOff(options).then((response) => {
      setTotal(response.rowCount);
      return response?.results?.map((result) => ({
        key: result.timeOffId,
        type: result.timeOffReasonName,
        duration: result.duration,
        startDate: formatDate(new Date(result.startDate + "Z")),
        endDate: formatDate(new Date(result.endDate + "Z")),
        name: `${result.employee.firstName} ${result.employee.lastName}`,
        title: result.note,
        requestedOn: new Date(result.createdDateTime + "Z").toDateString(),
        status: result.timeOffStatus,
      }));
    });
  };

  useMemo(async () => {
    const timeOffOps = new TimeOffOperation();
    await timeOffOps
      .getTimeOffStatAsync(selectedCompany?.corporationId as number)
      .then((value) => {
        if (value.statusCode === 200) {
          setStats(value.data!);
        }
      });
  }, [selectedCompany, userInfo]);

  // Initial Data Load.
  useEffect(() => {
    const options: RequestTimeOffParam = {
      includeDirectReport: false,
      page: 1,
      pageSize: 10,
    };
    fetchTimeOffData(options)
      .then(setMyTimeOff)
      .then(() => setLoading(false));

    setLoading(false);
  }, [updateTimeOff, selectedCompany, userInfo]);

  useEffect(() => {
    const employeeOps = new EmployeeOperation();
    const employeeId = userInfo?.employeeCorporation?.find(
      (x) => x.corporationId === selectedCompany?.corporationId
    )?.employeeId;

    employeeOps
      .getEmployeeByIdAsync(
        selectedCompany?.corporationId as number,
        employeeId ?? ""
      )
      .then((x) => {
        if (x.hasDirectReport) {
          setIsManager(true);
        }
      });
  }, [selectedCompany, userInfo]);

  const [responsive, setResponsive] = useState(false);
  const imgStyle = {
    display: "block",
    width: 42,
    height: 42,
  };

  const onCreateTimeOff = (values: any) => {
    console.log(values);
    setOpenTieOffModal(false);
    // This is just so that i can trigger
    // UserEffect call to get the latest schedule.
    SetUpdateTimeOff(!updateTimeOff);
    //Show Alert successfully inserted schedule???
    message.success("Successfully processed your request.");
  };

  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isSmallScreen = windowWidth <= 576;

  const handleTimeoffAction = async (
    index: number,
    timeOffId: React.Key,
    status: string
  ) => {
    const timeOffOps = new TimeOffOperation();
    await timeOffOps
      .postScheduleTimeOffApprovalAsync(
        selectedCompany?.corporationId as number,
        timeOffId.toString(),
        status
      )
      .then((value) => {
        if (value.statusCode === 200) {
          message.success("Successfully processed your request.");
          SetUpdateTimeOff(!updateTimeOff);
        } else {
          message.error(
            "An error has occurred while processing your request, please try again!"
          );
        }
      })
      .catch((e) => {
        message.error(
          "An error has occurred while processing your request, please try again!"
        );
      });
  };

  const columns: ColumnsType<TimeOffRequestsType> = [
    {
      title: "Leave Type",
      dataIndex: "type",
      width: "5%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "10%",
      responsive: ["sm"],
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      width: "10%",
      responsive: ["sm", "xs"],
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      width: "10%",
      responsive: ["sm"],
    },
    {
      title: "Title",
      dataIndex: "title",
      width: "10%",
      responsive: ["lg"],
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
      width: "10%",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          <Tag
            key={status}
            color={
              status === "Requested"
                ? "geekblue"
                : status === "Approved"
                ? "green"
                : "volcano"
            }
          >
            {status}{" "}
          </Tag>
        </>
      ),
      responsive: ["sm"],
      width: "10%",
    },
    {
      title: "Action",
      render: (_, record, index) => (
        <>
          <Space size="middle">
            {record.status === "Requested" ? (
              <Button
                type="default"
                danger
                icon={<CloseOutlined />}
                size={"small"}
                onClick={() =>
                  handleTimeoffAction(index, record.key, timeOffStatus.CANCEL)
                }
              >
                Cancel
              </Button>
            ) : null}
          </Space>
        </>
      ),
      width: isSmallScreen ? "50%" : "20%",
    },
  ];

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <PageContainer
      extra={[
        isManager ? (
          <Button
            icon={<UserOutlined />}
            onClick={() => {
              handleEmpTimeOffClick();
            }}
          >
            Employee Request(s)
          </Button>
        ) : null,
        isManager ? (
          <Button
            icon={<AuditOutlined />}
            onClick={() => {
              handleEmpTimeOffClick();
            }}
          >
            Pending Approval(s)
          </Button>
        ) : null,
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setOpenTieOffModal(true);
          }}
        >
          Request Time Off
        </Button>,
      ]}
      subTitle="Take full control over your time off requests with seamless viewing and management capabilities."
    >
      <RcResizeObserver
        key="resize-observer"
        onResize={(offset) => {
          setResponsive(offset.width < 596);
        }}
      >
        <StatisticCard.Group
          direction={responsive ? "column" : "row"}
          style={{ marginTop: 24 }}
        >
          <StatisticCard
            statistic={{
              title: "Total leaves for the year",
              value: stats?.totalRequestsCount ?? 0,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/bubbles/100/good-quality.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Pending leaves",
              value: stats?.pendingRequestsCount ?? 0,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/bubbles/100/clock.png"
                  alt="icon"
                />
              ),
            }}
          />
          <StatisticCard
            statistic={{
              title: "Rejected leaves for the year",
              value: stats?.rejectedRequestsCount ?? 0,
              icon: (
                <img
                  style={imgStyle}
                  src="https://img.icons8.com/external-bearicons-flat-bearicons/64/external-rejected-approved-and-rejected-bearicons-flat-bearicons.png"
                  alt="icon"
                />
              ),
            }}
          />
        </StatisticCard.Group>
      </RcResizeObserver>
      <ProCard style={{ marginTop: 24 }}>
        <Title level={3}>Requests History</Title>
        <Row gutter={16} style={{ marginTop: "32px" }}>
          <Col span={24}>
            <Table
              expandable={
                isSmallScreen
                  ? {
                      expandedRowRender: (record) => (
                        <>
                          Title: {record.title}
                          <br />
                          Date : {record.startDate} {record.endDate}
                          <br />
                          Duration: {record.duration}
                          <br />
                          Status:{" "}
                          <Tag
                            key={record.status}
                            color={
                              record.status === "Requested"
                                ? "geekblue"
                                : record.status === "Approved"
                                ? "green"
                                : "volcano"
                            }
                          >
                            {record.status}{" "}
                          </Tag>
                        </>
                      ),
                      rowExpandable: (record) =>
                        record.name !== "Not Expandable",
                    }
                  : undefined
              }
              pagination={{
                current: currentPage,
                total: total,
                //showSizeChanger: true,
                pageSize: pageSize,
                onChange: async (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  setLoading(true);
                  await fetchTimeOffData({
                    includeDirectReport: false,
                    page: page,
                    pageSize: pageSize,
                  }).then(setMyTimeOff);
                  setLoading(false);
                },
              }}
              style={{ whiteSpace: "pre" }}
              columns={columns}
              dataSource={myTimeOff}
              expandRowByClick
              loading={loading}
            />
          </Col>
        </Row>
        <TimeOffRequestModal
          id={1}
          open={openTieOffModal}
          onCreate={onCreateTimeOff}
          onCancel={() => {
            setOpenTieOffModal(false);
          }}
        />
      </ProCard>
    </PageContainer>
  );
};

export default TimeOff;
