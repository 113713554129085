import { Space, Card, Col, Row, Radio, Typography, Alert } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { CompanyContext } from "../../contexts/company-context";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { ResponseEmployeeAvailability } from "../../serviceoperation/entity/EmployeeOperationEntity";

const MyAvailability = (): JSX.Element => {
  const { Title, Paragraph, Text } = Typography;
  const { selectedCompany } = useContext(CompanyContext);
  const employeeOps = useMemo(() => new EmployeeOperation(), []);
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const [EmployeeActivity, SetEmployeeAvailability] = useState<
    ResponseEmployeeAvailability[]
  >([]);

  // Using this effect call to fetch the Employee List
  useMemo(() => {
    employeeOps
      .getEmployeeAvailabilityAsync(
        selectedCompany?.corporationId as number,
        userInfo?.employeeCorporation
          ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
          .map((x) => x.employeeId)
          .at(0) as string
      )
      .then((x) => {
        if (x.statusCode === 200) {
          SetEmployeeAvailability(x.data ?? []);
        }
      });
  }, [employeeOps, selectedCompany]);

  return (
    <div style={{ margin: "44px" }}>
      <Row gutter={[16, 24]}>
        <Col lg={18} md={24} xs={24}>
          <Title level={2}>
            <>Scheduling Preferences!</>
          </Title>
          <Alert
            message="Note: Selecting preferences does not guarantee that they will be honored."
            type="info"
            showIcon
          />
          <br></br>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col
          span={12}
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
          md={{ span: 24, order: 1 }}
          lg={{ span: 18, order: 0 }}
          xl={{ span: 18, order: 0 }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Card title="Days Of The Week" size="default">
              <Row gutter={[16, 16]}>
                <Col span={1}></Col>
                <Col span={23}>
                  <Text style={{ textAlign: "center" }}>
                    <b>NONE</b> - No Preference <b>ON</b> - Prefer to work{" "}
                    <b>OFF</b> - Prefer to be off work
                  </Text>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Sunday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 0)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Monday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 1)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Tuesday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 2)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Wednesday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 3)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Thursday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 4)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Friday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 5)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>

                <Col span={4} xs={8} sm={4} lg={{ span: 4 }} xl={{ span: 2 }}>
                  <p>Saturday</p>
                </Col>
                <Col
                  span={20}
                  xs={16}
                  sm={20}
                  lg={{ span: 20 }}
                  xl={{ span: 22 }}
                >
                  <Radio.Group
                    defaultValue="None"
                    value={
                      EmployeeActivity.filter((x) => x.dayIndex === 6)
                        .map((x) => (x.available ? "On" : "Off"))
                        .at(0) ?? "None"
                    }
                    size="middle"
                  >
                    <Radio.Button value="None">None</Radio.Button>
                    <Radio.Button value="On">On</Radio.Button>
                    <Radio.Button value="Off">Off</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            </Card>
            {/*<Card title="General Scheduling Preferences" size="small">
              <p>Holiday schedule?</p>
              <p>Birthday Schedule?</p>
            </Card>*/}
          </Space>
        </Col>
        <Col
          span={12}
          xs={{ span: 0, order: 0 }}
          sm={{ span: 0, order: 0 }}
          md={{ span: 0, order: 0 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
        />
      </Row>
    </div>
  );
};

export default MyAvailability;
