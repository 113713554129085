import { useContext, useEffect  } from "react";
import { AuthenticationContext } from "../contexts/authentication-context";
import { access } from "fs";

export const useMSALInterceptor = () => {
  // get the MSAL instance and the accounts
  const {acquireToken, accessToken} = useContext(AuthenticationContext)!;
  acquireToken();
  //const accessToken = useContext(AuthenticationContext)?.accessToken;
  const apiDomains = ["mytimeverse.com", "inviteuser-usc-af.azurewebsites.net", "localhost"];


  // setup an effect that runs whenever the instance or account changes
  useEffect(() => {
    const setTokenAndFetch = async () => {

      // save the original window.fetch function
      const { fetch: originalFetch } = window;

      // override window.fetch to add the authorization header
      window.fetch = async (...args: any[]) => {
        const [resource, config ] = args;
        const url = new URL(resource);
        
        if (apiDomains.some(domain => url.hostname.includes(domain))) {
            if(accessToken)
            {
              config.headers.set('Authorization', 'Bearer ' + accessToken);
            }
        }
        
        // call the original fetch function with the resource and configuration
        const response = await originalFetch(resource, config);
        return response;
      };
    };
    // run the setTokenAndFetch function
    setTokenAndFetch();
  }, [accessToken]);
};