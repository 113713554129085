import {
  LoginOutlined,
  LogoutOutlined,
  CoffeeOutlined,
} from "@ant-design/icons";
import { Col, Button, Row, Typography, theme, message } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import {
  ResponseScheduleActivity,
  Result,
  ScheduleList,
} from "../../serviceoperation/entity/ScheduleOperationEntity";
import { ScheduleOperation } from "../../serviceoperation/ScheduleOperation";
import { AmPMFormat, ConvertTo12hr, formatDate } from "../../Util/dateutil";
import { QuickActionCard } from "./schedule-quikcaction-types";
import { timeOffStatus } from "../../constants";

interface quickActionProp {
  schedleInfo?: Result;
}
const { useToken } = theme;
//const ProfileDetail: React.FC<profileDetail> = (profileDetail) => {

const ScheduleQuickAction: React.FC<quickActionProp> = (quickActionProp) => {
  const { Title, Paragraph, Text } = Typography;
  const { token } = useToken();
  const { selectedCompany } = useContext(CompanyContext);
  const userInfo = useContext(AuthenticationContext)?.userInfo;

  const [displayClockIn, setDisplayClockIn] = useState<boolean>(false);
  const [displayClockOut, setDisplayClockOut] = useState<boolean>(false);
  const [displayEndBreak, setDisplayEndBreak] = useState<boolean>(false);
  const [displayBreak, setDisplayBreak] = useState<boolean>(false);
  const [quickActionCard, setQuickActionCard] = useState<QuickActionCard>();
  const [clockInActivity, setClockInActivity] = useState<boolean>(false);
  const [clockedOutActivity, setClockedOutActivity] = useState<boolean>(false);
  const [currSchedule, setCurrSchedule] = useState<ScheduleList>();
  const [activity, SetActivity] = useState<ResponseScheduleActivity>();

  const handelActivityClick = (activityType: string) => {
    const scheduleOps = new ScheduleOperation();
    scheduleOps
      .postScheduleActivityAsync(
        selectedCompany?.corporationId ?? 0,
        quickActionCard?.scheduleID ?? 0,
        quickActionCard?.empId ?? "",
        activityType,
        0,
        0,
        0
      )
      .then((value) => {
        if (value.statusCode === 201) {
          switch (activityType) {
            case "CLOCK_IN": {
              setDisplayClockIn(false);
              setDisplayBreak(true);
              message.success("Successfully processed your clock in request.");
              break;
            }
            case "CLOCK_OUT": {
              setDisplayClockIn(false);
              setDisplayBreak(false);
              setDisplayEndBreak(false);
              message.success(
                "Successfully processed your clocked out request"
              );
              break;
            }
            case "BREAK_START": {
              setDisplayClockIn(false);
              setDisplayBreak(false);
              setDisplayEndBreak(true);
              message.success("Successfully processed your break request.");
              break;
            }
            case "BREAK_END": {
              setDisplayClockIn(false);
              setDisplayBreak(true);
              setDisplayEndBreak(false);
              message.success(
                "Successfully processed your resume work request."
              );
              break;
            }
            default: {
              message.success("Successfully processed your request.");
              break;
            }
          }
        }
      })
      .catch((e) => {
        message.error(
          "An error has occurred while processing your request, please try again!"
        );
      });
  };

  // This will fetch the Quick Card Method for ease activity
  // This method will determine the current schedule for the
  // QuickAction.
  useEffect(() => {
    if (!quickActionProp?.schedleInfo) {
      const scheduleOps = new ScheduleOperation();
      const currDate = new Date();
      scheduleOps
        .getScheduleAsync(selectedCompany?.corporationId as number, {
          startDate: currDate.toDateString(),
          endDate: new Date(
            currDate.setDate(currDate.getDate() - currDate.getDay() + 6)
          ).toDateString(),
          employeeId: userInfo?.employeeCorporation
            ?.filter((x) => x.corporationId === selectedCompany?.corporationId)
            .map((x) => x.employeeId)
            .at(0),
          page: 1,
          pageSize: 50,
        })
        .then((schedule) => {
          //Save the Results
          setCurrSchedule(schedule);
        });
    }
    // Call the GetSchedule with Start and EndTime of the week....
  }, [userInfo]);

  // This will process the QuickCard Activity so that
  // We can determine which actions to show.
  useEffect(() => {
    let quickCardSchedule: any;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      quickActionProp?.schedleInfo &&
      new Date(quickActionProp.schedleInfo.workDate).getDate() ===
        today.getDate()
    ) {
      quickCardSchedule = quickActionProp.schedleInfo;
    } else if (currSchedule != undefined && currSchedule.rowCount > 0) {
      quickCardSchedule = currSchedule.results[0];
    }

    if (quickCardSchedule) {
      const scheduleOps = new ScheduleOperation();
      scheduleOps
        .getScheduleActivityAsync(
          selectedCompany?.corporationId as number,
          quickCardSchedule.scheduleID
        )
        .then((value) => {
          if (value != undefined) {
            SetActivity(value);
            setDisplayClockIn(
              value?.options?.some(
                (x) => x.activityType.toString() === "CLOCK_IN"
              )
            );

            setDisplayClockOut(
              value?.options?.some(
                (x) => x.activityType.toString() === "CLOCK_OUT"
              )
            );

            setDisplayBreak(
              value?.options?.some(
                (x) => x.activityType.toString() === "BREAK_START"
              )
            );

            setDisplayEndBreak(
              value?.options?.some(
                (x) => x.activityType.toString() === "BREAK_END"
              )
            );

            setClockInActivity(
              value?.activityHistory?.some(
                (x) => x.activityType.toString() === "CLOCK_IN"
              )
            );

            setClockedOutActivity(
              value?.activityHistory?.some(
                (x) => x.activityType.toString() === "CLOCK_OUT"
              )
            );
          }
        });
    }
  }, [currSchedule, displayClockIn, displayBreak, displayEndBreak]);

  useEffect(() => {
    //
    if ((!currSchedule && !quickActionProp?.schedleInfo) || !activity) {
      return;
    }

    // This check is to make sure we don't show the clock in options when there is a
    // timeoff request that is approved or pending approval.
    if (
      (currSchedule?.results[0].timeoffDetail &&
        (currSchedule.results[0].timeoffDetail.timeOffStatusId ===
          timeOffStatus.APPROVE ||
          currSchedule.results[0].timeoffDetail.timeOffStatusId ===
            timeOffStatus.REQUESTED)) ||
      (quickActionProp?.schedleInfo &&
        quickActionProp.schedleInfo?.timeoffDetail &&
        (quickActionProp.schedleInfo.timeoffDetail.timeOffStatusId ===
          timeOffStatus.APPROVE ||
          quickActionProp.schedleInfo.timeoffDetail.timeOffStatusId ===
            timeOffStatus.REQUESTED))
    ) {
      return;
    }

    const schedule = currSchedule
      ? currSchedule.results[0]
      : quickActionProp.schedleInfo;

    const now = dayjs().format("MM/DD/YYYY");
    const [clockedInDate, clockedOutDate] = ["CLOCK_IN", "CLOCK_OUT"]
      .map(
        (type) =>
          activity.activityHistory?.find((x) => x.activityType === type)
            ?.activityDateTime
      )
      .map((date) => date);

    setQuickActionCard({
      title:
        formatDate(schedule!.workDate).valueOf() === now.valueOf()
          ? "Current Shift"
          : "Next Shift",
      empId: schedule!.employee.employeeID,
      scheduleTitle: schedule!.title,
      scheduleID: schedule!.scheduleID,
      duration: `${ConvertTo12hr(schedule!.startWorkHour)}:${
        schedule!.startWorkMinute
      } ${AmPMFormat(schedule!.startWorkHour)} - ${ConvertTo12hr(
        schedule!.endWorkHour
      )}:${schedule!.endWorkMinute} ${AmPMFormat(schedule!.endWorkHour)}`,
      userClockedIn: clockInActivity,
      clockedInSince: clockInActivity
        ? new Date(clockedInDate + "Z").toLocaleTimeString([], {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "",
      userClockedOut: clockedOutActivity,
      clockedOutSince: clockedOutActivity
        ? new Date(clockedOutDate + "Z").toLocaleTimeString([], {
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })
        : "",
      clockInAndOutMessage:
        clockInActivity && clockedOutActivity
          ? "Your shift has ended"
          : clockInActivity
          ? "You have been clocked in since"
          : "",
      lineSeperater: clockedOutActivity ? " - " : "",
      date: new Date(schedule!.workDate).toLocaleDateString("en-us", {
        timeZone: "UTC",
        weekday: "long",
        month: "short",
        day: "numeric",
      }),
    });
  }, [activity]);

  return (
    <div>
      {quickActionCard && (
        <>
          <Title level={5} style={{ marginTop: 20 }}>
            {quickActionCard.title}
          </Title>
          <Text style={{ fontSize: "15px" }}>{quickActionCard.date}</Text>
          <Paragraph style={{ fontSize: "15px" }}>
            {quickActionCard.duration}
          </Paragraph>
          <Paragraph style={{ fontSize: "15px" }}>
            Downtown Kansas City, MO
          </Paragraph>
          <Text style={{ fontSize: "15px" }}>
            {quickActionCard.clockInAndOutMessage}
          </Text>
          <Paragraph style={{ fontSize: "24px", color: token.colorPrimary }}>
            {quickActionCard.clockedInSince}
            {quickActionCard.lineSeperater}
            {quickActionCard.clockedOutSince}
          </Paragraph>
          {displayClockIn && (
            <Button
              type="primary"
              icon={<LoginOutlined />}
              style={{
                margin: 0,
                marginBottom: 20,
                width: "215px",
              }}
              onClick={() => handelActivityClick("CLOCK_IN")}
            >
              Clock In
            </Button>
          )}
          {displayBreak && (
            <>
              <Button
                danger
                onClick={() => handelActivityClick("CLOCK_OUT")}
                icon={<LogoutOutlined />}
              >
                Clock Out
              </Button>
              <Button
                style={{
                  margin: 0,
                  marginLeft: 10,
                  marginBottom: 20,
                }}
                onClick={() => handelActivityClick("BREAK_START")}
                icon={<CoffeeOutlined />}
                type="default"
              >
                Take a break
              </Button>
            </>
          )}
          {displayEndBreak && (
            <Button
              icon={<CoffeeOutlined />}
              style={{
                margin: 0,
                marginBottom: 20,
              }}
              onClick={() => handelActivityClick("BREAK_END")}
              type="default"
            >
              End a break
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ScheduleQuickAction;
