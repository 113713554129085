import React, { useState } from "react";
import { Button, Card, Space, Typography, theme } from "antd";
import {
  SmileOutlined,
  MehOutlined,
  FrownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { NewCorpEntity } from "./corp-new-entity";

const planIcons: any = {
  Free: <SmileOutlined />,
  Pro: <MehOutlined />,
  Enterprise: <FrownOutlined />,
};

const checkIcon = <CheckOutlined style={{ color: "#52c41a" }} />;
const { Title, Paragraph } = Typography;
interface AddCorpPlanFormProps {
  newCorpData: NewCorpEntity;
  onCreate: (values: NewCorpEntity) => void;
}

const pricingOptions = [
  {
    label: "Basic",
    price: 0,
    description: "Taste our product",
    features: ["Feature 1", "Feature 2"],
  },
  {
    label: "Pro",
    price: 19.99,
    description: "Make individual product",
    features: ["Feature 1", "Feature 2", "Feature 3"],
  },
  {
    label: "Enterprise",
    price: 49.99,
    description: "For large organization",
    features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
  },
];

const PricingPage: React.FC<AddCorpPlanFormProps> = ({
  newCorpData,
  onCreate,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const { useToken } = theme;
  const { token } = useToken();

  const handleCardClick = (label: string) => {
    setSelectedOption(label);
  };

  return (
    <div style={{ padding: "20px", minHeight: "100vh" }}>
      <Title
        level={1}
        style={{ marginBottom: "40px", fontSize: "24px", textAlign: "center" }}
      >
        A simple plan for every company
      </Title>
      <Space direction="horizontal" size="large" wrap>
        {pricingOptions.map((option) => (
          <Card
            key={option.label}
            onClick={() => handleCardClick(option.label)}
            hoverable
            style={{
              width: "300px",
              height: "350px", // Set fixed height for all cards
              borderRadius: "10px",
              border: selectedOption === option.label ? "2px solid orange" : "",
              boxShadow:
                selectedOption === option.label
                  ? "0px 0px 20px 0px rgba(0,0,0,0.1)"
                  : "none",
              marginBottom: "20px",
              textAlign: "left",
            }}
          >
            <div style={{ marginBottom: "5px" }}>{planIcons[option.label]}</div>
            <Title level={3}>{option.label}</Title>
            <Paragraph style={{ color: "#6b778c", marginBottom: "10px" }}>
              {option.description}
            </Paragraph>
            <Title level={2}>
              {option.price === 0
                ? "Free"
                : `$${option.price.toFixed(2)} / month`}
            </Title>
            <ul style={{ listStyleType: "none", padding: "0" }}>
              {option.features.map((feature, index) => (
                <li key={index}>
                  {checkIcon} {feature}
                </li>
              ))}
            </ul>
          </Card>
        ))}
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            onCreate(newCorpData);
          }}
        >
          Next
        </Button>
      </Space>
    </div>
  );
};

export default PricingPage;
