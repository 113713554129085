function AmPMFormat(str: number){
    //${s.startWorkHour <= 12 ? "AM":"PM"}
    return str < 12 ? "AM":"PM"
}

function ConvertTo12hr(str: number){
    return ((str + 11) % 12 + 1)
}

function ConvertTo12hrStr(str: number): string {
  const hour12 = ((str + 11) % 12) + 1;
  return hour12 < 10 ? `0${hour12}` : hour12.toString();
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

function formatDate(date: Date) {
    date = new Date(date);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

  const getFormattedDateTime = (dateTimeString?: string) => {
    if (dateTimeString) {
      return `${new Date(dateTimeString + "Z").toLocaleString()}`;
    }
    return "";
  };

  function getFirstAndLastDayOfWeek(date: Date): [Date, Date] {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(date);
    startOfWeek.setDate(startOfWeek.getDate() - dayOfWeek);
    const endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() + (6 - dayOfWeek));
    return [startOfWeek, endOfWeek];
  }
  
export { AmPMFormat, ConvertTo12hr, ConvertTo12hrStr,padTo2Digits, formatDate, getFirstAndLastDayOfWeek, getFormattedDateTime };