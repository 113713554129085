import { UserOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Input,
  Row,
  Select,
  Typography,
  UploadProps,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { userInfo } from "os";
import { useContext, useEffect, useState } from "react";
import { EmployeeOperation } from "../../serviceoperation/EmployeeOperation";
import { AuthenticationContext } from "../../contexts/authentication-context";
import { CompanyContext } from "../../contexts/company-context";
import { TimeZoneOperation } from "../../serviceoperation/TimezoneOperation";
import { TimeZoneType } from "../schedule/modal/timeZone-types";
import { EmployeeDetails } from "../../serviceoperation/entity/EmployeeOperationEntity";

interface profileDetail {
  empDetails: EmployeeDetails;
}

const ProfileDetail: React.FC<profileDetail> = (profileDetail) => {
  const userInfo = useContext(AuthenticationContext)?.userInfo;
  const { selectedCompany } = useContext(CompanyContext);
  const { Text } = Typography;
  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const [timeZone, setTimeZone] = useState<TimeZoneType[]>([]);
  useEffect(() => {
    const timeZoneOps = new TimeZoneOperation();
    timeZoneOps.getTimeZoneAsync().then((value) => {
      setTimeZone(
        value.map((s) => ({
          key: s.Id,
          name: s.Id,
        }))
      );
    });
  }, []);

  return (
    <Row gutter={[16, 24]} style={{ marginTop: "24px" }}>
      <Col
        span={8}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 8, order: 1 }}
        lg={{ span: 8, order: 1 }}
        xl={{ span: 8, order: 1 }}
      >
        <Text>First Name</Text>
        <Input disabled placeholder={profileDetail.empDetails?.firstName} />
      </Col>
      <Col
        span={8}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 8, order: 1 }}
        lg={{ span: 8, order: 1 }}
        xl={{ span: 8, order: 1 }}
      >
        <Text>Last Name</Text>
        <Input disabled placeholder={profileDetail.empDetails?.lastName} />
      </Col>
      <Col
        span={8}
        xs={{ span: 0, order: 0 }}
        sm={{ span: 0, order: 0 }}
        md={{ span: 8, order: 3 }}
        lg={{ span: 8, order: 3 }}
        xl={{ span: 8, order: 3 }}
      />

      <Col
        span={12}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      >
        <Text>Email</Text>
        <Input disabled placeholder={profileDetail.empDetails?.email} />

        <div style={{ marginTop: 24 }}>
          <Text>Manager</Text>
          <Input disabled placeholder={profileDetail.empDetails?.managerName} />
        </div>

        <div style={{ marginTop: 24 }}>
          <Text>Branch</Text>
          <Input
            disabled
            placeholder={profileDetail.empDetails?.department?.name}
          />
        </div>

        <div style={{ marginTop: 24 }}>
          <Text>Department</Text>
          <Input
            disabled
            placeholder={profileDetail.empDetails?.department?.name}
          />
        </div>
      </Col>
      <Col
        span={12}
        xs={{ span: 0, order: 0 }}
        sm={{ span: 0, order: 0 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      />

      <Col
        span={12}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      >
        <Text>Timezone</Text>
        <Select
          placeholder="America\Chicago"
          disabled
          allowClear
          showSearch
          optionFilterProp="children"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={timeZone.map((tmz) => {
            return {
              value: tmz.key,
              label: tmz.name,
            };
          })}
        />
      </Col>
      <Col
        span={12}
        xs={{ span: 0, order: 0 }}
        sm={{ span: 0, order: 0 }}
        md={{ span: 12, order: 1 }}
        lg={{ span: 12, order: 1 }}
        xl={{ span: 12, order: 1 }}
      />

      <Col
        span={1}
        xs={{ span: 2, order: 1 }}
        sm={{ span: 2, order: 1 }}
        md={{ span: 2, order: 1 }}
        lg={{ span: 2, order: 1 }}
        xl={{ span: 2, order: 1 }}
      >
        <Avatar size={48} icon={<UserOutlined />} />
      </Col>
      <Col
        span={14}
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 14, order: 2, offset: 1 }}
        lg={{ span: 14, order: 2 }}
        xl={{ span: 14, order: 2 }}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from
            uploading company data or other band files
          </p>
        </Dragger>
      </Col>
      <Col
        span={8}
        xs={{ span: 0, order: 0 }}
        sm={{ span: 0, order: 0 }}
        md={{ span: 8, order: 3 }}
        lg={{ span: 8, order: 3 }}
        xl={{ span: 8, order: 3 }}
      />
    </Row>
  );
};

export default ProfileDetail;
